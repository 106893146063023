import { IconBrandDiscord, IconBrandTelegram } from '@tabler/icons-react'
import { BadgeDollarSign, Infinity } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import { Button } from '@/components/ui/button'
import { ModeToggle } from '@/components/ui/mode-toggle'
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
} from '@/components/ui/navigation-menu'
import config from '@/config'
import { cn } from '@/lib/utils'
import { useCredits } from '@/provider/credit-provider'
import useQuery from '@/provider/query-provider'

const Header = () => {
    const { credits, maskTokens, isFetching } = useCredits()
    const { setParam } = useQuery()

    const openCredits = () => {
        setParam('credits', 'true')
    }

    return (
        <div className='bg-background border-b top-0 z-20 sticky'>
            {/* <div className='flex w-full p-2 justify-center text-center  items-center bg-secondary gap-2'>
                <div
                    className='cursor-pointer'
                    onClick={() => {
                        event(`ad-card-nude-bot`, {
                            category: 'content',
                            label: 'nude-bot',
                        })
                        window.open(config.clBoturl, '_blank')
                    }}
                >
                    Try our new
                    <SparklesText
                        className='text-sm sm:text-md inline px-2'
                        text='AI Clothes Removal Bot'
                        sparklesCount={5}
                    />
                    for free!
                </div>
            </div> */}
            <div className='flex flex-row w-full justify-between items-center'>
                <div className='flex items-center flex-row'>
                    <a className='flex items-center cursor-pointer' href='/'>
                        <Image
                            className='w-10 m-2 rounded-md object-cover aspect-square'
                            src='/generated/android-chrome-72x72.png'
                            height={50}
                            width={50}
                            alt='logo'
                        />
                        <h2 className='hidden sm:block text-lg font-semibold'>Leakshaven</h2>
                    </a>

                    <NavigationMenu className='ml-2 hidden sm:block'>
                        <NavigationMenuList>
                            <NavigationMenuItem>
                                <NavigationMenuTrigger>ToS & Privacy</NavigationMenuTrigger>
                                <NavigationMenuContent className=''>
                                    <ul className='grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]'>
                                        <ListItem key='tos' href='/tos' title='Terms of Service'>
                                            Our terms of service
                                        </ListItem>
                                        <ListItem
                                            href='/privacy-policy'
                                            key='privacy-policy'
                                            title='Privacy Policy'
                                        >
                                            Privacy policy and data collection
                                        </ListItem>
                                        <ListItem href='/dmca' key='dmca' title='DMCA'>
                                            DMCA and content removal
                                        </ListItem>
                                        <ListItem
                                            href='/18-usc-2257'
                                            key='18-usc-2257'
                                            title='18 USC 2257'
                                        >
                                            18 USC 2257 compliance
                                        </ListItem>
                                    </ul>
                                </NavigationMenuContent>
                            </NavigationMenuItem>
                        </NavigationMenuList>
                    </NavigationMenu>

                    <Button
                        variant='outline'
                        className='m-1 hidden sm:flex justify-between items-center'
                        asChild
                    >
                        <Link href='https://theporndude.com' target='_blank'>
                            <Image
                                width='50'
                                height='50'
                                className='h-[1.2rem] w-[1.2rem]'
                                src='images/thepornodude.webp'
                                alt='ThePornDude'
                            />
                            <div className='ml-1 '>ThePornDude</div>
                        </Link>
                    </Button>
                </div>

                <div className='flex items-center flex-row'>
                    <Button
                        variant='outline'
                        onClick={openCredits}
                        className='flex items-center border-0 animate-glow justify-center text-amber-300 mr-3'
                    >
                        {!isFetching ? (
                            !maskTokens ? (
                                <>
                                    <span className='text-md '>{String(credits)}</span>
                                    <BadgeDollarSign className='ml-2 h-[1.2rem] w-[1.2rem]' />
                                </>
                            ) : (
                                <Infinity className='h-4 w-4 ' />
                            )
                        ) : (
                            <BadgeDollarSign className='ml-2 h-[1.2rem] w-[1.2rem] animate-spin' />
                        )}
                    </Button>
                    <Button
                        variant='outline'
                        className='m-1 md:hover:animate-telegram'
                        size='icon'
                        asChild
                    >
                        <Link href={config.telegramChannel} target='_blank'>
                            <IconBrandTelegram className='h-[1.2rem] w-[1.2rem] text-blue-300' />
                        </Link>
                    </Button>
                    <Button
                        variant='outline'
                        className='m-1 md:hover:animate-discord'
                        size='icon'
                        asChild
                    >
                        <Link href={config.discordServer} target='_blank'>
                            <IconBrandDiscord className='h-[1.2rem] w-[1.2rem] text-purple-300' />
                        </Link>
                    </Button>
                    <Button variant='outline' size='icon' className='m-1 sm:hidden visible' asChild>
                        <Link href='https://theporndude.com' target='_blank'>
                            <Image
                                width='50'
                                height='50'
                                className='h-[1.2rem] w-[1.2rem]'
                                src='images/thepornodude.webp'
                                alt='ThePornDude'
                            />
                        </Link>
                    </Button>
                    <ModeToggle />
                </div>
            </div>
        </div>
    )
}

const ListItem = React.forwardRef<React.ElementRef<'a'>, React.ComponentPropsWithoutRef<'a'>>(
    ({ className, title, children, ...props }, ref) => {
        return (
            <li>
                <NavigationMenuLink asChild>
                    <a
                        ref={ref}
                        className={cn(
                            'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
                            className
                        )}
                        {...props}
                    >
                        <div className='text-sm font-medium leading-none'>{title}</div>
                        <p className='line-clamp-2 text-sm leading-snug text-muted-foreground'>
                            {children}
                        </p>
                    </a>
                </NavigationMenuLink>
            </li>
        )
    }
)
ListItem.displayName = 'ListItem'

export default Header
