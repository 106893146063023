import { IconBrandDiscord, IconBrandTelegram } from '@tabler/icons-react'
import { ShoppingCart } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { Sheet, SheetContent, SheetDescription, SheetHeader } from '@/components/ui/sheet'
import { AccountCard } from '@/components/User/AccountCard'
import config from '@/config'
import { cn } from '@/lib/utils'
import useQuery from '@/provider/query-provider'

import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/card'

const creditLoadOptions = [
    {
        title: 'AdMaven',
        count: 3,
        link: `${config.apiUrl}/credits/moreAdMaven`,
        image: '/images/admaven.png',
        cardClass: 'from-admaven-bg',
    },
    // {
    //     title: 'Linkvertise',
    //     count: 3,
    //     link: `${config.apiUrl}/credits/more`,
    //     image: '/images/linkvertise.png',
    //     cardClass: 'from-lv-accent-bg',
    // },
    // {
    //     title: 'Work.ink',
    //     count: 2,
    //     link: `${config.apiUrl}/credits/moreWorkInk`,
    //     image: '/images/work-ink.png',
    //     cardClass: 'from-work-ink-bg',
    // },
]

const CreditLoadCards = () =>
    creditLoadOptions.map((option) => (
        <Card
            key={option.title}
            className={cn(
                'h-full flex  flex-col bg-gradient-to-br  to-transparent flex-grow',
                option.cardClass
            )}
        >
            <CardHeader className=''>
                <CardTitle className='flex justify-center  items-center'>
                    <Image
                        alt='admaven logo'
                        width='4'
                        height='4'
                        src={option.image}
                        className='mr-2 h-5 w-5 rounded'
                    />
                    {option.title}
                </CardTitle>
            </CardHeader>
            <CardDescription className='flex flex-grow  m-0 p-0 '></CardDescription>
            <CardFooter className='flex justify-center  p-2 m-2 pt-0 mt-0 items-center'>
                <Button className='cursor-pointer w-full' variant='outline' asChild>
                    <a href={option.link} target='_blank'>
                        Free <b className='mr-1 ml-1'> {option.count} </b> Credits
                    </a>
                </Button>
            </CardFooter>
        </Card>
    ))

export const CreditCard = () => {
    const { setParam, query } = useQuery()

    return (
        <Sheet
            open={!!query.credits}
            onOpenChange={() => {
                setParam('credits', undefined)
            }}
        >
            <SheetContent className='sm:max-w-lg w-auto overflow-x-scroll'>
                <SheetHeader className='mt-3 '>
                    <AccountCard />
                    <SheetDescription>
                        <div className=' text-lg mb-2'>Need more credits?</div>
                        <div className='grid grid-cols-1 sm:grid-cols-2 flex-row gap-4 items-start flex-wrap'>
                            <Card className='h-full flex flex-col bg-gradient-to-br from-account-bg to-transparent flex-grow'>
                                <CardHeader className=''>
                                    <CardTitle className='flex justify-center  items-center'>
                                        <Image
                                            alt='lh logo'
                                            width='5'
                                            height='5'
                                            src='/generated/android-chrome-72x72.png'
                                            className='mr-2 h-6 w-6 rounded'
                                        />
                                        Premium
                                    </CardTitle>
                                </CardHeader>
                                <CardDescription className='flex justify-center flex-grow items-center mb-2 '>
                                    Starting at <b className='ml-1'>$4.99</b>
                                </CardDescription>
                                <CardFooter className='flex justify-center  items-center p-2 m-2'>
                                    <Button className='cursor-pointer w-full' variant='default' asChild>
                                        <Link href={`/plans`}>
                                            <ShoppingCart className='h-4 w-4 mr-2' />
                                            Get Premium
                                        </Link>
                                    </Button>
                                </CardFooter>
                            </Card>
                            <CreditLoadCards />
                        </div>
                        <div className='flex mt-3'>
                            <Card className='flex flex-grow flex-col'>
                                <CardHeader className=' b-2'>
                                    <CardTitle className=''>Get in touch!</CardTitle>
                                    <CardDescription>
                                        For any support related queries feel free to contact us below.
                                        <br />
                                        AD-Partners feel free to contact us and work together
                                    </CardDescription>
                                </CardHeader>
                                <Separator className=' mb-1' />
                                <CardFooter className='flex items-center  p-2  space-x-2 '>
                                    <Button className='cursor-pointer' variant='outline' asChild>
                                        <a href={`${config.discordServer}`} target='_blank'>
                                            <IconBrandDiscord className='mr-2 h-4 w-4' />
                                            Discord
                                        </a>
                                    </Button>
                                    <Button className='cursor-pointer' variant='outline' asChild>
                                        <a href={`${config.telegramLink}`} target='_blank'>
                                            <IconBrandTelegram className='mr-2 h-4 w-4' />
                                            Telegram
                                        </a>
                                    </Button>
                                </CardFooter>
                            </Card>
                        </div>
                    </SheetDescription>
                </SheetHeader>
            </SheetContent>
        </Sheet>
    )
}

export default CreditCard
