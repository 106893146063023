import { Duration } from 'luxon'
import { event } from 'nextjs-google-analytics'
import { createContext } from 'react'
import * as React from 'react'

import { increaseTimedNumberValueKey, timedNumberValueKeyIsOlderThan } from '@/lib/timedKey'
import { useCredits } from '@/provider/credit-provider'

interface ExternalAdsProviderContext {
    openAds: (e?: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLFormElement>) => boolean
}

const ExternalAdsProviderContext = createContext<ExternalAdsProviderContext>(
    {} as ExternalAdsProviderContext
)

export const ExternalAdsProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { creditConfig } = useCredits()

    const trackAdsKey = 'ta'
    const trackLongAdsKey = 'tx'

    const openAds = (e?: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLFormElement>) => {
        return false
        if (
            timedNumberValueKeyIsOlderThan({
                key: trackAdsKey,
                time: Duration.fromObject({ second: 10 }),
            }) <= 0 &&
            creditConfig.planFree &&
            timedNumberValueKeyIsOlderThan({
                key: trackLongAdsKey,
                time: Duration.fromObject({ minute: 8 }),
            }) <= 5
        ) {
            e?.preventDefault()
            increaseTimedNumberValueKey(trackAdsKey)
            increaseTimedNumberValueKey(trackLongAdsKey)

            event('ads', {
                category: 'content',
            })
            window.open('https://pulseadnetwork.com/jump/next.php?r=8376938', '_blank')
            return true
        } else {
            return false
        }
    }
    return (
        <ExternalAdsProviderContext.Provider value={{ openAds }}>
            {children}
        </ExternalAdsProviderContext.Provider>
    )
}

export const useExternalAds = () => React.useContext(ExternalAdsProviderContext)
