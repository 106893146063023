export const indetifyFromEmail = (email: string) => {
    if (isReady()) {
        // @ts-ignore
        window.clarity('identify', email)
    }
}

export const isReady = () => {
    // @ts-ignore
    return typeof window.clarity === 'function'
}
