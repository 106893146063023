import { useQuery } from '@tanstack/react-query'
import { BadgeDollarSign } from 'lucide-react'
import { useRouter } from 'next/router'
import { event } from 'nextjs-google-analytics'
import { useEffect } from 'react'
import { toast } from 'sonner'

import { redeemCreditCode } from '@/api/api'
import { useCredits } from '@/provider/credit-provider'

export const RedeemCredits: React.FC = () => {
    const { query, push } = useRouter()
    const { refresh } = useCredits()

    const { isSuccess, data } = useQuery({
        queryKey: ['redeem', query.redeem],
        queryFn: () => redeemCreditCode(query.redeem as string),
        enabled: typeof query.redeem === 'string',
    })

    useEffect(() => {
        if (isSuccess && data && data.success) {
            refresh()

            toast('Credits redeemed!', {
                duration: 5000,
                dismissible: true,
                icon: <BadgeDollarSign className='h-4 w-4' />,
                description: `You have successfully redeemed ${data.credits} credits!`,
            })
            event('redeem_code', {
                category: 'tokens',
                value: data.credits,
            })
            setTimeout(() => {
                push(`/`, undefined, { scroll: false, shallow: true })
            }, 3000)
        } else if (typeof query.redeem === 'string') {
            toast('Error redeeming credits!', {
                duration: 5000,
                dismissible: true,
                icon: <BadgeDollarSign className='h-4 w-4' />,
                description: `There was an error redeeming the credits!`,
            })
        }
    }, [isSuccess])

    return <></>
}

export default RedeemCredits
