import { BadgeDollarSign, Infinity } from 'lucide-react'
import { DateTime } from 'luxon'
import Link from 'next/link'
import React from 'react'

import { apiLogout } from '@/api/api'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { cutOfString } from '@/lib/cutOfString'
import { cn } from '@/lib/utils'
import { useCredits } from '@/provider/credit-provider'

import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from '../ui/card'

export function AccountCard() {
    const { credits, maskTokens, creditConfig, refresh } = useCredits()

    // capitalize the first letter of the string config?.frame
    const restockTime =
        `${creditConfig?.frame}`.charAt(0).toUpperCase() +
        `${creditConfig?.frame}`.slice(1).toLocaleLowerCase() +
        ((creditConfig?.frameCounter || 0) > 1 ? 's' : '')

    return (
        <div className='flex'>
            <Card className='flex flex-grow flex-col relative'>
                <div
                    className={cn(
                        'px-3 py-1 text-sm font-bold text-white rounded-full inline-block absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
                        creditConfig.planFree
                            ? 'bg-gradient-to-r from-green-800 to-green-500'
                            : 'bg-gradient-to-r from-pink-500 to-purple-500'
                    )}
                >
                    {creditConfig.planTitle}
                </div>
                <CardHeader className='p-2 pl-3 pr-3'>
                    <CardTitle className='flex justify-between items-center flex-grow flex-wrap'>
                        <div>
                            <h1 className='text-lg overflow-hidden'>
                                {!creditConfig.anonymous
                                    ? cutOfString(creditConfig.email, 30)
                                    : 'Anonymous'}
                            </h1>
                        </div>

                        {maskTokens ? (
                            <div className='flex justify-center items-center bg-amber-600  pr-2 pl-2 rounded-md p-2'>
                                <Infinity className=' h-6 w-6' />
                            </div>
                        ) : (
                            <div className='flex justify-center items-center bg-amber-600  pr-2 pl-2 rounded-md'>
                                <p className='m-2'>{maskTokens ? 'infinite' : credits} </p>
                                <BadgeDollarSign className=' h-6 w-6' />
                            </div>
                        )}
                    </CardTitle>
                    <CardDescription className='mt-2 pb-2'>
                        {!maskTokens && (
                            <p>
                                Credits are restored at {creditConfig.restock} Credits{' '}
                                <b>
                                    every {creditConfig.frameCounter} {restockTime}
                                </b>
                            </p>
                        )}
                        {creditConfig.validUntil && (
                            <p>
                                Plan valid until:{' '}
                                <b>
                                    {DateTime.fromJSDate(new Date(creditConfig.validUntil)).toISODate()}
                                </b>
                            </p>
                        )}
                    </CardDescription>
                </CardHeader>
                <Separator orientation='horizontal' />
                {creditConfig.anonymous ? (
                    <CardFooter className='flex items-center  p-2  space-x-2 '>
                        <Button className='cursor-pointer' variant='secondary' asChild>
                            <Link href={`/auth?type=signup`}>Sign Up</Link>
                        </Button>
                        <Button className='cursor-pointer' variant='secondary' asChild>
                            <Link href={`/auth?type=login`}>Login</Link>
                        </Button>
                    </CardFooter>
                ) : (
                    <CardFooter className='flex items-center  p-2  space-x-2 '>
                        <Button
                            className='cursor-pointer'
                            onClick={() => {
                                apiLogout()
                                refresh()
                            }}
                            variant='destructive'
                        >
                            Log Out
                        </Button>
                    </CardFooter>
                )}
            </Card>
        </div>
    )
}
