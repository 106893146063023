import React, { createContext, useState } from 'react'

import { FakeCardProps } from '@/components/Content/FakeCard'
import { fakeCardsData } from '@/fakeCards'
import { useCredits } from '@/provider/credit-provider'
import { Content } from '@/types/content.type'

interface TAdPlacementContext {
    insertOnPage: (index: number, content: Content[]) => (Content | FakeCardProps)[]
}

interface AdPlacementSlot {
    position: number
    campaign: string
    adData?: FakeCardProps
}

type AdPlacement = AdPlacementSlot[][]

const initialAdPlacementContext: TAdPlacementContext = {} as TAdPlacementContext

const AdPlacementContext = createContext<TAdPlacementContext>(initialAdPlacementContext)

export const AdPlacementProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { creditConfig } = useCredits()
    const [adPlacement, setAdPlacement] = useState<AdPlacement>([])

    const insertAdPlacement = (index: number, content: (Content | FakeCardProps)[]) => {
        const adPlacementsOnPage = adPlacement[index]
        for (const adPlacementSlot of adPlacementsOnPage) {
            if (adPlacementSlot.position < content.length && adPlacementSlot.adData) {
                content.splice(adPlacementSlot.position, 0, adPlacementSlot.adData)
            }
        }
        return content
    }

    const insertOnPage = (index: number, content: (Content | FakeCardProps)[]) => {
        if (!creditConfig.planFree) {
            return content
        }
        const adPlacementsOnPage = adPlacement[index]

        if (adPlacementsOnPage && adPlacementsOnPage.length == fakeCardsData.length) {
            return insertAdPlacement(index, content)
        } else {
            adPlacement[index] = []
            fakeCardsData.forEach((fakeCardData) => {
                // make sure to place ads with a distance of at least 4
                //    const randomPosition = Math.floor(Math.random() * 20)
                let randomPosition = Math.floor(Math.random() * 20)
                let tries = 0
                while (
                    adPlacement[index].find((ad) => Math.abs(ad.position - randomPosition) < 3) &&
                    tries < 50
                ) {
                    tries++
                    randomPosition = Math.floor(Math.random() * 20)
                }

                if (Math.random() < fakeCardData.rate) {
                    adPlacement[index].push({
                        position: randomPosition,
                        campaign: fakeCardData.campaign,
                        adData: fakeCardData,
                    })
                } else {
                    adPlacement[index].push({
                        position: randomPosition,
                        campaign: fakeCardData.campaign,
                    })
                }
            })
            setAdPlacement(adPlacement)

            return insertAdPlacement(index, content)
        }
    }

    return <AdPlacementContext.Provider value={{ insertOnPage }}>{children}</AdPlacementContext.Provider>
}

export const useAdPlacement = () => {
    const context = React.useContext(AdPlacementContext)
    if (!context) {
        throw new Error('useAdPlacement must be used within a AdPlacementProvider')
    }
    return context
}
