import '@/styles/globals.css'

import { TrackingHeadScript } from '@phntms/next-gtm'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { AppProps } from 'next/app'
import { Inter as FontSans } from 'next/font/google'
import Script from 'next/script'
import { ReCaptchaProvider } from 'next-recaptcha-v3'
import { DefaultSeo } from 'next-seo'
import { ThemeProvider } from 'next-themes'
import { GoogleAnalytics } from 'nextjs-google-analytics'

import { queryClient } from '@/api/queryClient'
import HeadMeta from '@/components/AppShell/HeaderMeta'
import AppShell from '@/components/AppShell/Shell'
import CreditCard from '@/components/credit-card'
import RedeemCredits from '@/components/redeem-credits'
import { Toaster } from '@/components/ui/sonner'
import config, { defaultSeoConfig } from '@/config'
import { cn } from '@/lib/utils'
import ProviderWrapper from '@/provider/provider-wrapper'

const fontSans = FontSans({
    subsets: ['latin'],
})

export default function App({ Component, pageProps }: AppProps) {
    return (
        <main className={cn('min-h-screen bg-background font-sans antialiased', fontSans.className)}>
            {!process.env.NEXT_PUBLIC_ENV_DEV_MODE && (
                <>
                    <GoogleAnalytics trackPageViews strategy='afterInteractive' />
                    <TrackingHeadScript id={config.gtmId} isGTM={true} />
                    <Script
                        id='msclarity'
                        dangerouslySetInnerHTML={{
                            __html: `
                                  (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${config.msClarityId}");
                            `,
                        }}
                    />
                </>
            )}

            <DefaultSeo {...defaultSeoConfig} />

            <HeadMeta />
            <ReCaptchaProvider>
                <ThemeProvider attribute='class' defaultTheme='dark' enableSystem>
                    <QueryClientProvider client={queryClient}>
                        <ProviderWrapper>
                            <CreditCard />
                            <RedeemCredits />
                            <AppShell>
                                <Component {...pageProps} />
                            </AppShell>
                        </ProviderWrapper>

                        {process.env.NEXT_PUBLIC_ENV_DEV_MODE && (
                            <ReactQueryDevtools initialIsOpen buttonPosition='bottom-left' />
                        )}
                    </QueryClientProvider>
                    <Toaster />
                </ThemeProvider>
            </ReCaptchaProvider>
        </main>
    )
}
